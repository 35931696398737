/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum UserEventName {
  USER_CREATE = 'user_create',
  COMPLETE_PERSON_BASIC_INFO = 'complete_person_basic_info',
  COMPLETE_PERSON_ADDRESS = 'complete_person_address',
  COMPLETE_DIGITAL_CERTIFICATE = 'complete_digital_certificate',
  COMPLETE_BIOMETRICS_REPORT_SUBMIT = 'complete_biometrics_report_submit',
  COMPLETE_PROFESSION = 'complete_profession',
  COMPLETE_PROFESSIONAL_DOCUMENTS = 'complete_professional_documents',
  COMPLETE_SPECIALTIES = 'complete_specialties',
  COMPLETE_COMPANY_INFO = 'complete_company_info',
  COMPLETE_COMPANY_DOCUMENTS = 'complete_company_documents',
  COMPLETE_PF = 'complete_pf',
  COMPLETE_COMPANY_BANK_ACCOUNT = 'complete_company_bank_account',
  COMPLETE_PERSON_BANK_ACCOUNT = 'complete_person_bank_account',
  COMPLETE_COMPLIANCE = 'complete_compliance',
  COMPLETE_USER_TERMS = 'complete_user_terms',
  REJECT_PERSON_BASIC_INFO = 'reject_person_basic_info',
  REJECT_PERSON_ADDRESS = 'reject_person_address',
  REJECT_DIGITAL_CERTIFICATE = 'reject_digital_certificate',
  REJECT_BIOMETRICS_REPORT_SUBMIT = 'reject_biometrics_report_submit',
  REJECT_PROFESSION = 'reject_profession',
  REJECT_PROFESSIONAL_DOCUMENTS = 'reject_professional_documents',
  REJECT_SPECIALTIES = 'reject_specialties',
  REJECT_COMPANY_INFO = 'reject_company_info',
  REJECT_COMPANY_DOCUMENTS = 'reject_company_documents',
  REJECT_COMPANY_BANK_ACCOUNT = 'reject_company_bank_account',
  REJECT_COMPLIANCE = 'reject_compliance',
  REJECT_USER_TERMS = 'reject_user_terms',
  PERSONAL_INFO_APPROVED = 'personal_info_approved',
  PERSONAL_INFO_DENIED = 'personal_info_denied',
  PROFESSIONAL_INFO_APPROVED = 'professional_info_approved',
  PROFESSIONAL_INFO_DENIED = 'professional_info_denied',
  COMPANY_INFO_APPROVED = 'company_info_approved',
  COMPANY_INFO_DENIED = 'company_info_denied',
}
