/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class WorkflowApi {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Validate if current user logged in user is allowed to access page requested.
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public validateWorkflowStep(
    requestBody: {
      url: string;
    },
  ): CancelablePromise<({
    success: boolean;
    dependencies: Array<string>;
  } | {
    success: boolean;
    metadata: {
      nextUrl: string;
    };
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/workflow/validate-step',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List completed and uncompleted steps for a given user
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public userSituation(): CancelablePromise<{
    success: boolean;
    data?: {
      completedStates: Array<string>;
      uncompletedStates: Array<string>;
    };
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/workflow/situation',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Find user next step in onboarding workflow.
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public nextStep(): CancelablePromise<{
    success: boolean;
    data?: {
      nextStep: string;
    };
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/user/me/workflow/next-step',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

}
