/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $FeatureFlags = {
  properties: {
    CONTRACT_MODULE: {
      type: 'boolean',
      isRequired: true,
    },
    CONTRACT_REVIEWER: {
      type: 'boolean',
      isRequired: true,
    },
    CONTRACT_REMUNERATION: {
      type: 'boolean',
      isRequired: true,
    },
    HAS_JURIDIC_REVIEWER: {
      type: 'boolean',
      isRequired: true,
    },
    DISABLE_RATE_LIMIT: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_SERPRO_NO_EI_VALIDATION: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_SERPRO_NO_MEI_VALIDATION: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_COMPANY_SHALLOW_NAME_MATCH: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_LEAD_COLLECTION: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_LEAD_EMAIL: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_NEOWAY_VALIDATION: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_NEOWAY_BACKOFFICE: {
      type: 'boolean',
      isRequired: true,
    },
    DISABLE_BANK_ACCOUNT_VALIDATION: {
      type: 'boolean',
      isRequired: true,
    },
    DISABLE_EMAIL_SENDING: {
      type: 'boolean',
      isRequired: true,
    },
    DISABLE_SERPRO_CPF_STATUS_CHECK: {
      type: 'boolean',
      isRequired: true,
    },
    DISABLE_DOMAIN_PROVIDER_VALIDATION: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_USER_PROVIDER_OVERRIDE: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_RETURN_TO_GTA: {
      type: 'boolean',
      isRequired: true,
    },
    TAX_FORM_NEW_SEND_BUTTON: {
      type: 'boolean',
      isRequired: true,
    },
    PROFESSIONAL_SYNC_BUTTON: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_COMMENT_SECTION: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_COMPLIANCE_SECTION: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_MULTI_PROFESSION: {
      type: 'boolean',
      isRequired: true,
    },
    AMIL_SAP_BP_INTEGRATION: {
      type: 'boolean',
      isRequired: true,
    },
    AMIL_SAP_BP_SEND_ZMED_ID: {
      type: 'boolean',
      isRequired: true,
    },
    AMIL_SAP_BP_SEND_IND_SECTOR_80: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_GDM_DISAPPROVAL_ON_REGISTER_DENY: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_PRE_APPROVAL: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_BPKIND_NOTIFICATION: {
      type: 'boolean',
      isRequired: true,
    },
    PROFESSIONAL_MARKETPLACE: {
      type: 'boolean',
      isRequired: true,
    },
    WAIT_BIOMETRICS_AFTER_REGISTER: {
      type: 'boolean',
      isRequired: true,
    },
    ACCREDITATION: {
      type: 'boolean',
      isRequired: true,
    },
    ENABLE_TAX_FORM: {
      type: 'boolean',
      isRequired: true,
    },
    MULTISTEP_APPROVAL: {
      type: 'boolean',
      isRequired: true,
    },
    MULTISTEP_DENIAL: {
      type: 'boolean',
      isRequired: true,
    },
  },
} as const;
