/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdminInfoResponse } from '../models/AdminInfoResponse';
import type { ApproveDoctorExtraSpecialtiyRequest } from '../models/ApproveDoctorExtraSpecialtiyRequest';
import type { ComplianceApprovalRequest } from '../models/ComplianceApprovalRequest';
import type { ComplianceDisapprovalRequest } from '../models/ComplianceDisapprovalRequest';
import type { ComplianceReturnRequest } from '../models/ComplianceReturnRequest';
import type { Contract } from '../models/Contract';
import type { ContractApproveRequest } from '../models/ContractApproveRequest';
import type { ContractCancelRequest } from '../models/ContractCancelRequest';
import type { ContractCancelResponse } from '../models/ContractCancelResponse';
import type { ContractInfoResponseData } from '../models/ContractInfoResponseData';
import type { ContractRequestChangesRequest } from '../models/ContractRequestChangesRequest';
import type { ContractReviewerListResponse } from '../models/ContractReviewerListResponse';
import type { CreateApprovalCompetencyRequest } from '../models/CreateApprovalCompetencyRequest';
import type { CreateApprovalCompetencyResponse } from '../models/CreateApprovalCompetencyResponse';
import type { CreateCommentRequest } from '../models/CreateCommentRequest';
import type { CreateCommentResponse } from '../models/CreateCommentResponse';
import type { CreateContract } from '../models/CreateContract';
import type { CreateContractResponse } from '../models/CreateContractResponse';
import type { CreateContractReviewerRequest } from '../models/CreateContractReviewerRequest';
import type { CreateContractReviewerResponse } from '../models/CreateContractReviewerResponse';
import type { DenyDoctorExtraSpecialtiyRequest } from '../models/DenyDoctorExtraSpecialtiyRequest';
import type { EditCommentRequest } from '../models/EditCommentRequest';
import type { EditCommentResponse } from '../models/EditCommentResponse';
import type { EditContract } from '../models/EditContract';
import type { ExportReportRequest } from '../models/ExportReportRequest';
import type { FeatureFlagsResponse } from '../models/FeatureFlagsResponse';
import type { GdmApprovalRequest } from '../models/GdmApprovalRequest';
import type { GdmDisapprovalRequest } from '../models/GdmDisapprovalRequest';
import type { GetApprovalCompetencyRequest } from '../models/GetApprovalCompetencyRequest';
import type { GetApprovalCompetencyResponse } from '../models/GetApprovalCompetencyResponse';
import type { GetBackgroundCheckRequest } from '../models/GetBackgroundCheckRequest';
import type { GetBackgroundCheckResponseData } from '../models/GetBackgroundCheckResponseData';
import type { GetBusinessPartnerBukrsRequest } from '../models/GetBusinessPartnerBukrsRequest';
import type { GetBusinessPartnerBukrsResponse } from '../models/GetBusinessPartnerBukrsResponse';
import type { GetCompanyBusinessPartnerIdRequest } from '../models/GetCompanyBusinessPartnerIdRequest';
import type { GetCompanyBusinessPartnerIdResponse } from '../models/GetCompanyBusinessPartnerIdResponse';
import type { GetCompanyToHireRequest } from '../models/GetCompanyToHireRequest';
import type { GetCompanyToHireResponse } from '../models/GetCompanyToHireResponse';
import type { GetContractReviewerRequest } from '../models/GetContractReviewerRequest';
import type { GetContractReviewerResponse } from '../models/GetContractReviewerResponse';
import type { GetContractStatusHistoryRequest } from '../models/GetContractStatusHistoryRequest';
import type { GetContractStatusHistoryResponse } from '../models/GetContractStatusHistoryResponse';
import type { GetContractTemplateRequest } from '../models/GetContractTemplateRequest';
import type { GetContractTemplateResponse } from '../models/GetContractTemplateResponse';
import type { GetMarketplaceBackgroundCheckRequest } from '../models/GetMarketplaceBackgroundCheckRequest';
import type { GetSerproDataRequest } from '../models/GetSerproDataRequest';
import type { GetSerproDataResponse } from '../models/GetSerproDataResponse';
import type { Hirer } from '../models/Hirer';
import type { ListApprovalCompetenciesRequest } from '../models/ListApprovalCompetenciesRequest';
import type { ListApprovalCompetenciesResponse } from '../models/ListApprovalCompetenciesResponse';
import type { ListAvailableDoctorsSpecialtiesResponse } from '../models/ListAvailableDoctorsSpecialtiesResponse';
import type { ListBanksResponse } from '../models/ListBanksResponse';
import type { ListByValueRequest } from '../models/ListByValueRequest';
import type { ListByValueResponse } from '../models/ListByValueResponse';
import type { ListCommentsRequest } from '../models/ListCommentsRequest';
import type { ListCommentsResponse } from '../models/ListCommentsResponse';
import type { ListCompanyMembersData } from '../models/ListCompanyMembersData';
import type { ListContractReviewersRequest } from '../models/ListContractReviewersRequest';
import type { ListContractReviewersResponse } from '../models/ListContractReviewersResponse';
import type { ListContractsByCompanyRequest } from '../models/ListContractsByCompanyRequest';
import type { ListContractsByCompanyResponse } from '../models/ListContractsByCompanyResponse';
import type { ListContractsRequest } from '../models/ListContractsRequest';
import type { ListContractsResponse } from '../models/ListContractsResponse';
import type { ListContractTemplatesRequest } from '../models/ListContractTemplatesRequest';
import type { ListContractTemplatesResponse } from '../models/ListContractTemplatesResponse';
import type { ListDoctorExtraSpecialtiesRequest } from '../models/ListDoctorExtraSpecialtiesRequest';
import type { ListDoctorExtraSpecialtiesResponse } from '../models/ListDoctorExtraSpecialtiesResponse';
import type { ListHiredContractsRequest } from '../models/ListHiredContractsRequest';
import type { ListHiredContractsResponse } from '../models/ListHiredContractsResponse';
import type { ListHirersResponse } from '../models/ListHirersResponse';
import type { ListProfessionalsRequest } from '../models/ListProfessionalsRequest';
import type { ListProfessionalsResponse } from '../models/ListProfessionalsResponse';
import type { ListProvidersResponse } from '../models/ListProvidersResponse';
import type { ListResponse } from '../models/ListResponse';
import type { ListShiftManagerGroupsResponse } from '../models/ListShiftManagerGroupsResponse';
import type { ListShiftManagerPositionsResponse } from '../models/ListShiftManagerPositionsResponse';
import type { ListSignersRequest } from '../models/ListSignersRequest';
import type { ListSignersResponse } from '../models/ListSignersResponse';
import type { MarketplaceProfessionalInfoRequest } from '../models/MarketplaceProfessionalInfoRequest';
import type { MarketplaceProfessionalInfoResponseData } from '../models/MarketplaceProfessionalInfoResponseData';
import type { MarketplaceProfessionalListRequest } from '../models/MarketplaceProfessionalListRequest';
import type { MarketplaceProfessionalListResponseData } from '../models/MarketplaceProfessionalListResponseData';
import type { PreRegisterApprovalRequest } from '../models/PreRegisterApprovalRequest';
import type { PreRegisterDenyRequest } from '../models/PreRegisterDenyRequest';
import type { ProfessionalComplianceInfoRequest } from '../models/ProfessionalComplianceInfoRequest';
import type { ProfessionalComplianceInfoResponse } from '../models/ProfessionalComplianceInfoResponse';
import type { ProfessionalInfoRequest } from '../models/ProfessionalInfoRequest';
import type { ProfessionalInfoResponse } from '../models/ProfessionalInfoResponse';
import type { ProfessionalLogsInfoResponseData } from '../models/ProfessionalLogsInfoResponseData';
import type { ProfessionalsStatusesCountResponse } from '../models/ProfessionalsStatusesCountResponse';
import type { ProfessionalUpdateComplianceScoreResponseData } from '../models/ProfessionalUpdateComplianceScoreResponseData';
import type { ProfessionInfo } from '../models/ProfessionInfo';
import type { RegistrationApproveRequest } from '../models/RegistrationApproveRequest';
import type { RegistrationDenyRequest } from '../models/RegistrationDenyRequest';
import type { RemoveCommentAttachmentRequest } from '../models/RemoveCommentAttachmentRequest';
import type { RemoveCommentRequest } from '../models/RemoveCommentRequest';
import type { RemoveCommentResponse } from '../models/RemoveCommentResponse';
import type { RemoveContractReviewerRequest } from '../models/RemoveContractReviewerRequest';
import type { RemoveContractReviewerResponse } from '../models/RemoveContractReviewerResponse';
import type { Response } from '../models/Response';
import type { SearchCompanyRequest } from '../models/SearchCompanyRequest';
import type { SearchCompanyResponse } from '../models/SearchCompanyResponse';
import type { SendNewTaxFormRequest } from '../models/SendNewTaxFormRequest';
import type { Signer } from '../models/Signer';
import type { Specialty } from '../models/Specialty';
import type { SyncProfessionalRequest } from '../models/SyncProfessionalRequest';
import type { UpdateApprovalCompetencyRequest } from '../models/UpdateApprovalCompetencyRequest';
import type { UpdateApprovalCompetencyResponse } from '../models/UpdateApprovalCompetencyResponse';
import type { UpdateContractReviewerRequest } from '../models/UpdateContractReviewerRequest';
import type { UpdateContractReviewerResponse } from '../models/UpdateContractReviewerResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class AdminService {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * Get the logged-in admin
   * @returns AdminInfoResponse The logged-in admin
   * @throws ApiError
   */
  public meAdmin(): CancelablePromise<AdminInfoResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/me',
    });
  }

  /**
   * List visible feature flags
   * @returns FeatureFlagsResponse List of feature flags
   * @throws ApiError
   */
  public listFeatureFlags(): CancelablePromise<FeatureFlagsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/feature/list',
    });
  }

  /**
   * Returns the professionals statuses count
   * @returns ProfessionalsStatusesCountResponse Returns professionals statuses count/APIError
   * @throws ApiError
   */
  public statusesCount(): CancelablePromise<ProfessionalsStatusesCountResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/statuses-count',
    });
  }

  /**
   * Returns the professional info
   * @param requestBody
   * @returns ProfessionalInfoResponse Returns professional info/APIError
   * @throws ApiError
   */
  public professionalInfo(
    requestBody: ProfessionalInfoRequest,
  ): CancelablePromise<ProfessionalInfoResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the professional compliance info
   * @param requestBody
   * @returns ProfessionalComplianceInfoResponse Returns professional info/APIError
   * @throws ApiError
   */
  public professionalComplianceInfo(
    requestBody: ProfessionalComplianceInfoRequest,
  ): CancelablePromise<ProfessionalComplianceInfoResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/compliance-info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Updated the professional score and return the new info
   * @param requestBody
   * @returns any Returns professional info/APIError
   * @throws ApiError
   */
  public professionalUpdateComplianceScore(
    requestBody: {
      professionalId: string;
    },
  ): CancelablePromise<(Response & {
    data: ProfessionalUpdateComplianceScoreResponseData;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/update-compliance-score',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the professional logs info
   * @param requestBody
   * @returns any Returns professional info/APIError
   * @throws ApiError
   */
  public professionalLogsInfo(
    requestBody: {
      professionalId: string;
    },
  ): CancelablePromise<(Response & {
    data: ProfessionalLogsInfoResponseData;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/logs',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the professional background check information
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public getProfessionalBackgroundCheck(
    requestBody: GetBackgroundCheckRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: GetBackgroundCheckResponseData;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/get-background-check',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Server error`,
      },
    });
  }

  /**
   * Gdm Approval by given professional and user id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public gdmApproval(
    requestBody: GdmApprovalRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/gdm-approval',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Compliance Approval by given professional and user id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public complianceApproval(
    requestBody: ComplianceApprovalRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/compliance-approval',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * GDM Disapproval by given professional and user id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public gdmDisapproval(
    requestBody: GdmDisapprovalRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/gdm-disapproval',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Compliance Disapproval by given professional and user id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public complianceDisapproval(
    requestBody: ComplianceDisapprovalRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/compliance-disapproval',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Compliance Return by given professional and user id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public complianceReturn(
    requestBody: ComplianceReturnRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/compliance-return',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Sync professional with integrated apps
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public syncProfessional(
    requestBody: SyncProfessionalRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/sync',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a report with all professionals
   * @param requestBody
   * @returns binary Returns professionals report
   * @throws ApiError
   */
  public exportReport(
    requestBody: ExportReportRequest,
  ): CancelablePromise<Blob> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/export-report',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Send a new tax form for the company related to the given person
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public sendNewTaxForm(
    requestBody: SendNewTaxFormRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/company/send-new-tax-form',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve business partner bukrs for a given CNPJ
   * @param requestBody
   * @returns GetBusinessPartnerBukrsResponse All Business Partner Bukrs found for a given CNPJ
   * @throws ApiError
   */
  public getBusinessPartnerBukrs(
    requestBody: GetBusinessPartnerBukrsRequest,
  ): CancelablePromise<GetBusinessPartnerBukrsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/company/get-bukrs',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Retrieve a list of company members for a given CNPJ
   * @param requestBody
   * @returns any A list of all company members found for the given CNPJ
   * @throws ApiError
   */
  public listCompanyMembers(
    requestBody: {
      cnpj: string;
    },
  ): CancelablePromise<(Response & {
    data: ListCompanyMembersData;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/company/list-company-members',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Searches a list of company using CNPJ or name
   * @param requestBody
   * @returns SearchCompanyResponse A list of all companies found for the given CNPJ or name
   * @throws ApiError
   */
  public searchCompany(
    requestBody: SearchCompanyRequest,
  ): CancelablePromise<SearchCompanyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/company/search-company',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get a company to be hired info using CNPJ
   * @param requestBody
   * @returns GetCompanyToHireResponse A list of all companies found for the given CNPJ or name
   * @throws ApiError
   */
  public getCompanyToHire(
    requestBody: GetCompanyToHireRequest,
  ): CancelablePromise<GetCompanyToHireResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/company/get-company-to-hire',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get a company BP id using CNPJ as param
   * @param requestBody
   * @returns GetCompanyBusinessPartnerIdResponse A list of all companies found for the given CNPJ or name
   * @throws ApiError
   */
  public getCompanyBusinessPartnerId(
    requestBody: GetCompanyBusinessPartnerIdRequest,
  ): CancelablePromise<GetCompanyBusinessPartnerIdResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/company/get-company-business-partner-id',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns the list of available MedSimples Shift Manager groups for professional
   * @returns ListShiftManagerGroupsResponse Returns professionals list/APIError
   * @throws ApiError
   */
  public listGroups(): CancelablePromise<ListShiftManagerGroupsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/shift-manager/list-groups',
    });
  }

  /**
   * Returns the list of available MedSimples Shift Manager positions
   * @returns ListShiftManagerPositionsResponse Returns positions list/APIError
   * @throws ApiError
   */
  public listPositions(): CancelablePromise<ListShiftManagerPositionsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/shift-manager/list-positions',
    });
  }

  /**
   * Return the list of banks for the current admin user
   * @returns ListBanksResponse List of banks
   * @throws ApiError
   */
  public listAdminBanks(): CancelablePromise<ListBanksResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/bank/list',
    });
  }

  /**
   * List the available specialties from CFM
   * @returns ListAvailableDoctorsSpecialtiesResponse Returns success/APIError
   * @throws ApiError
   */
  public listDoctorsSpecialties(): CancelablePromise<ListAvailableDoctorsSpecialtiesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/doctor/list-specialties',
    });
  }

  /**
   * List doctor's extra specialties
   * @param requestBody
   * @returns ListDoctorExtraSpecialtiesResponse Returns success/APIError
   * @throws ApiError
   */
  public listDoctorExtraSpecialties(
    requestBody: ListDoctorExtraSpecialtiesRequest,
  ): CancelablePromise<ListDoctorExtraSpecialtiesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/doctor/list-extra-specialties',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Approve doctor's extra specialty
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public approveDoctorExtraSpecialty(
    requestBody: ApproveDoctorExtraSpecialtiyRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/doctor/extra-specialty/approve',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Deny doctor's extra specialty
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public denyDoctorExtraSpecialty(
    requestBody: DenyDoctorExtraSpecialtiyRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/doctor/extra-specialty/deny',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returnsthe providers
   * @returns ListProvidersResponse Returns professionals list/APIError
   * @throws ApiError
   */
  public listProviders(): CancelablePromise<ListProvidersResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/provider/list',
    });
  }

  /**
   * List contracts
   * @param requestBody
   * @returns ListContractsResponse Returns success/APIError
   * @throws ApiError
   */
  public listContracts(
    requestBody: ListContractsRequest,
  ): CancelablePromise<ListContractsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List all hired companies with contracts
   * @param requestBody
   * @returns ListHiredContractsResponse Returns success/APIError
   * @throws ApiError
   */
  public listHiredContractData(
    requestBody: ListHiredContractsRequest,
  ): CancelablePromise<ListHiredContractsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/list-hired-company-contract-data',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get contract
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public getContract(
    requestBody: {
      contractId: string;
    },
  ): CancelablePromise<(Response & {
    data?: ContractInfoResponseData;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get contract status history
   * @param requestBody
   * @returns GetContractStatusHistoryResponse Returns success/APIError
   * @throws ApiError
   */
  public getContractStatusHistory(
    requestBody: GetContractStatusHistoryRequest,
  ): CancelablePromise<GetContractStatusHistoryResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/history',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Create contract
   * @param requestBody
   * @returns CreateContractResponse Returns success/APIError
   * @throws ApiError
   */
  public createContract(
    requestBody: CreateContract,
  ): CancelablePromise<CreateContractResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/create',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Edit contract
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public editContract(
    requestBody: EditContract,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/edit',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Add contract signer
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public addContractSigner(
    requestBody: {
      contractId?: string;
      signerId?: string;
    },
  ): CancelablePromise<(Response & {
    data: Contract;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/add-signer',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Submit contract
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public submitContract(
    requestBody: {
      contractId?: string;
    },
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/submit',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List contract templates
   * @param requestBody
   * @returns ListContractTemplatesResponse Returns success/APIError
   * @throws ApiError
   */
  public listContractTemplates(
    requestBody: ListContractTemplatesRequest,
  ): CancelablePromise<ListContractTemplatesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/template/list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get contract template
   * @param requestBody
   * @returns GetContractTemplateResponse Returns success/APIError
   * @throws ApiError
   */
  public getContractTemplate(
    requestBody: GetContractTemplateRequest,
  ): CancelablePromise<GetContractTemplateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/template/info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List signers
   * @param requestBody
   * @returns ListSignersResponse Returns success/APIError
   * @throws ApiError
   */
  public listSigners(
    requestBody: ListSignersRequest,
  ): CancelablePromise<ListSignersResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/signer/list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get signer
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public getSigner(
    requestBody: {
      id: string;
    },
  ): CancelablePromise<(Response & {
    data: Signer;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/signer/info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Create signer
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public createSigner(
    requestBody: Signer,
  ): CancelablePromise<(Response & {
    data: Signer;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/signer/create',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update signer
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public updateSigner(
    requestBody: Signer,
  ): CancelablePromise<(Response & {
    data: Signer;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/signer/update',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List hirers
   * @returns ListHirersResponse Returns success/APIError
   * @throws ApiError
   */
  public listHirers(): CancelablePromise<ListHirersResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/hirer/list',
    });
  }

  /**
   * Get hirer
   * @param requestBody
   * @returns any Returns success/APIError
   * @throws ApiError
   */
  public getHirer(
    requestBody: {
      id: string;
    },
  ): CancelablePromise<(Response & {
    data: Hirer;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/hirer/info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Create contract reviewer
   * @param requestBody
   * @returns CreateContractReviewerResponse Returns success/APIError
   * @throws ApiError
   */
  public createContractReviewer(
    requestBody: CreateContractReviewerRequest,
  ): CancelablePromise<CreateContractReviewerResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/reviewer/create',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get contract reviewer
   * @param requestBody
   * @returns GetContractReviewerResponse Returns success/APIError
   * @throws ApiError
   */
  public getContractReviewer(
    requestBody: GetContractReviewerRequest,
  ): CancelablePromise<GetContractReviewerResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/reviewer/info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List contract reviewer
   * @param requestBody
   * @returns ListContractReviewersResponse Returns success/APIError
   * @throws ApiError
   */
  public listContractReviewer(
    requestBody: ListContractReviewersRequest,
  ): CancelablePromise<ListContractReviewersResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/reviewer/list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * remove contract reviewer
   * @param requestBody
   * @returns RemoveContractReviewerResponse Returns success/APIError
   * @throws ApiError
   */
  public removeContractReviewer(
    requestBody: RemoveContractReviewerRequest,
  ): CancelablePromise<RemoveContractReviewerResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/reviewer/remove',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update contract reviewer
   * @param requestBody
   * @returns UpdateContractReviewerResponse Returns success/APIError
   * @throws ApiError
   */
  public updateContractReviewer(
    requestBody: UpdateContractReviewerRequest,
  ): CancelablePromise<UpdateContractReviewerResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/reviewer/update',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get approval competency
   * @param requestBody
   * @returns GetApprovalCompetencyResponse Returns success/APIError
   * @throws ApiError
   */
  public getApprovalCompetency(
    requestBody: GetApprovalCompetencyRequest,
  ): CancelablePromise<GetApprovalCompetencyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/approval-competency/info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List all approval competencies.
   * @param requestBody
   * @returns ListApprovalCompetenciesResponse Returns success/APIError
   * @throws ApiError
   */
  public listApprovalCompetency(
    requestBody: ListApprovalCompetenciesRequest,
  ): CancelablePromise<ListApprovalCompetenciesResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/approval-competency/list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Create an approval competency that approvers may have.
   * @param requestBody
   * @returns CreateApprovalCompetencyResponse Returns success/APIError
   * @throws ApiError
   */
  public createApprovalCompetency(
    requestBody: CreateApprovalCompetencyRequest,
  ): CancelablePromise<CreateApprovalCompetencyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/approval-competency/create',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Update an approval competency that approvers may have.
   * @param requestBody
   * @returns UpdateApprovalCompetencyResponse Returns success/APIError
   * @throws ApiError
   */
  public updateApprovalCompetency(
    requestBody: UpdateApprovalCompetencyRequest,
  ): CancelablePromise<UpdateApprovalCompetencyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/approval-competency/update',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Set status of ContractReviewerAssignment as DECLINED
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public contractRequestChanges(
    requestBody: ContractRequestChangesRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/request-changes',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get person data from serpro
   * @param requestBody
   * @returns GetSerproDataResponse Returns success/APIError
   * @throws ApiError
   */
  public getSerproData(
    requestBody: GetSerproDataRequest,
  ): CancelablePromise<GetSerproDataResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/serpro/info',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get contract reviewers with higher competency value than the given competency id value
   * @param requestBody
   * @returns ListByValueResponse Returns success/APIError
   * @throws ApiError
   */
  public listbyValue(
    requestBody: ListByValueRequest,
  ): CancelablePromise<ListByValueResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/reviewer/list-by-value',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Approve contract
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public approveContract(
    requestBody: ContractApproveRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/approve',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Cancel contract
   * @param requestBody
   * @returns ContractCancelResponse Returns success/APIError
   * @throws ApiError
   */
  public cancelContract(
    requestBody: ContractCancelRequest,
  ): CancelablePromise<ContractCancelResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/cancel',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List contracts grouped by company
   * @param requestBody
   * @returns ListContractsByCompanyResponse Returns success/APIError
   * @throws ApiError
   */
  public listContractsByCompany(
    requestBody: ListContractsByCompanyRequest,
  ): CancelablePromise<ListContractsByCompanyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/list-by-company',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Get contract reviewers list by its permissions
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public contractReviewerList(): CancelablePromise<{
    success: boolean;
    data?: ContractReviewerListResponse;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/contract/get-reviewer-user-list',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Approve a professional registration by given professional id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public registerApprove(
    requestBody: RegistrationApproveRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/register-approve',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Deny professional registration by given professional id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public registerDeny(
    requestBody: RegistrationDenyRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/register-deny',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns a list of filtered professionals
   * @param requestBody
   * @returns ListProfessionalsResponse The request has succeeded.
   * @throws ApiError
   */
  public listProfessionals(
    requestBody: ListProfessionalsRequest,
  ): CancelablePromise<ListProfessionalsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List all avaiable professions
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public adminListProfession(): CancelablePromise<{
    success: boolean;
    data?: Array<ProfessionInfo>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/profession/list',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        404: `The server cannot find the requested resource.`,
        500: `Server error`,
      },
    });
  }

  /**
   * List all avaible Specialties
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public listSpecialties(): CancelablePromise<{
    success: boolean;
    data?: Array<Specialty>;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/list-specialties',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Pre registration deny by given professional and user id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public preRegisterDeny(
    requestBody: PreRegisterDenyRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/pre-register-deny',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Pre registration approval by given professional and user id
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public preRegisterApprove(
    requestBody: PreRegisterApprovalRequest,
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/professional/pre-register-approve',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Imports a marketplace professional
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketaplaceProfessionalImport(
    requestBody: {
      professionalId: string;
      providerId: string;
    },
  ): CancelablePromise<{
    success: boolean;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/marketplace/import-professional',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns a list of marketplace avaible professionals
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalList(
    requestBody: MarketplaceProfessionalListRequest,
  ): CancelablePromise<(ListResponse & {
    data: Array<MarketplaceProfessionalListResponseData>;
  })> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/marketplace/list-professionals',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Returns a info of a marketplace professional
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalInfo(
    requestBody: MarketplaceProfessionalInfoRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: MarketplaceProfessionalInfoResponseData;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/marketplace/get-professional-info',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `The server could not understand the request due to invalid syntax.`,
        401: `Access is unauthorized.`,
        403: `Access is forbidden`,
        500: `Server error`,
      },
    });
  }

  /**
   * Returns the professional background check information
   * @param requestBody
   * @returns any The request has succeeded.
   * @throws ApiError
   */
  public marketplaceProfessionalBackgroundCheck(
    requestBody: GetMarketplaceBackgroundCheckRequest,
  ): CancelablePromise<{
    success: boolean;
    data?: GetBackgroundCheckResponseData;
    metadata?: Record<string, any>;
  }> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/marketplace/get-professional-background-check',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        500: `Server error`,
      },
    });
  }

  /**
   * Create new comment.
   * @param requestBody
   * @returns CreateCommentResponse Returns success/APIError
   * @throws ApiError
   */
  public createComment(
    requestBody: CreateCommentRequest,
  ): CancelablePromise<CreateCommentResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/comment/create',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Edit a existing comment.
   * @param requestBody
   * @returns EditCommentResponse Returns success/APIError
   * @throws ApiError
   */
  public editComment(
    requestBody: EditCommentRequest,
  ): CancelablePromise<EditCommentResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/comment/edit',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * List comments.
   * @param requestBody
   * @returns ListCommentsResponse Returns success/APIError
   * @throws ApiError
   */
  public listComments(
    requestBody: ListCommentsRequest,
  ): CancelablePromise<ListCommentsResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/comment/list',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Remove a existing comment.
   * @param requestBody
   * @returns RemoveCommentResponse Returns success/APIError
   * @throws ApiError
   */
  public removeComment(
    requestBody: RemoveCommentRequest,
  ): CancelablePromise<RemoveCommentResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/comment/remove',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * Remove a existing comment-attachment.
   * @param requestBody
   * @returns Response Returns success/APIError
   * @throws ApiError
   */
  public removeCommentAttachment(
    requestBody: RemoveCommentAttachmentRequest,
  ): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/admin/comment/remove-attachment',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

}
